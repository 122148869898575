import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../Slice/auth";

const Header = (props) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(logout())
      .unwrap()
      .then((data) => {
        navigate(`/login`);
      });
  };

  const linksContainerRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("user"));
  const collapseNav = (e, url) => {
    e.preventDefault();
    // navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
    // navigate(url);
    window.location.href = url;
  };
  var checkLogin = localStorage.getItem("token");
  const loginAsAdmin = (userId) => {
    window.location.href =
      process.env.REACT_APP_ADMIN_URL + "admin-login?user_id=" + userId;
  };
  const handleClick = (event) => {
    window.Produktly.openChangelog({ id: 250 });
    event.preventDefault();
  };
  return (
    <header className={checkLogin ? "" : "before-login"}>
      <div className="container-fluid">
        <div className="header">
          <div className="logo">
            <figure>
              <Link to={"/"}>
                <img src="../images/logo.png" alt="logo" />
              </Link>
            </figure>
          </div>
          <nav className="navbar navbar-expand-lg">
            <button
              id="nav-menus"
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
            {!checkLogin && (
              <div
                className="collapse navbar-collapse"
                id="navbarNavDropdown"
                ref={linksContainerRef}
              >
                <ul className="navbar-nav">
                  <>
                    {/* <li className="nav-item">
                      <a className="nav-link" href="#">
                        Sports
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <NavLink
                        onClick={(e) => collapseNav(e, "/features")}
                        className="nav-link"
                        // to="features"
                      >
                        Features
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        // to="/pricing"
                        onClick={(e) => collapseNav(e, "/pricing")}
                      >
                        Pricing
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <div className="dropdown ">
                        <button
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          About
                        </button>
                        <ul
                          className="dropdown-menu mobiledropdownmenu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(e) => collapseNav(e, "/about-us")}
                              to="/about-us"
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(e) => collapseNav(e, "/how-it-works")}
                            >
                              How It Works
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(e) => collapseNav(e, "/how-to-play")}
                            >
                              How To Play
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(e) => collapseNav(e, "/articles")}
                            >
                              Articles
                            </Link>
                          </li>

                          {/* <li>
                        <a className="dropdown-item" href="0">
                          Members
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="0">
                          Blog
                        </a>
                      </li> */}
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="dropdown ">
                        <button
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Hubs
                        </button>
                        <ul
                          className="dropdown-menu mobiledropdownmenu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(e) => {
                                e.preventDefault();
                                collapseNav(e, "/join-game");
                              }}
                            >
                              Fantasy Hubs
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(e) => collapseNav(e, "/live-games")}
                            >
                              Live Games
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li className="nav-item">
                      <div className="dropdown">
                        <button
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Signup
                        </button>
                        <ul
                          className="dropdown-menu mobiledropdownmenu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(e) => {
                                e.preventDefault();
                                collapseNav(e, "/member-signup");
                              }}
                            >
                              As Member
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(e) => collapseNav(e, "/start-game")}
                            >
                              Start Game
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </>

                  {!checkLogin && (
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        onClick={(e) => collapseNav(e, "/login")}
                      >
                        Login
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            )}

            {checkLogin && (
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="" href="/my-games">
                      <i
                        className="fas fa-home"
                        size="lg"
                        style={{ color: "#ffffff", fontSize: "30px" }}
                      ></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <figure>
                          {userData?.image && (
                            <img
                              src={`${process.env.REACT_APP_USER_PROFILE}${userData?.image}`}
                              alt="img"
                            />
                          )}
                          {!userData?.image && (
                            <img src={`../images/user-img.png`} alt="img" />
                          )}
                        </figure>
                      </Link>

                      <ul
                        className="dropdown-menu mobiledropdownmenu logindropdown"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to={`/member-profile/${userDetails?.slug}`}
                          >
                            MY PROFILE
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={"/update-profile"}
                          >
                            EDIT PROFILE
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={"/change-password"}
                          >
                            PASSWORD
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to={`/billings`}>
                            BILLINGS
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" onClick={handleClick}>
                            WHAT'S NEW
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={`https://myclubtap.tawk.help/category/for-members`}
                            target="_blank"
                          >
                            HELP CENTER
                          </Link>
                        </li>
                        {userDetails?.user_role_id === 3 && (
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={() => loginAsAdmin(userDetails?.id)}
                            >
                              ADMIN
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link className="dropdown-item" onClick={logoutUser}>
                            LOGOUT
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
