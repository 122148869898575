import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GoogleAdsense from "./GoogleAdsense";
import { globalFunction } from "../../Helpers/globalFunctions";
import PointModal from "../Dashboard/PointModal";
import RoundModal from "../ModalBox/RoundModal";
import ReactHtmlParser from "react-html-parser";
import PaymentModal from "../Dashboard/PaymentModal";
import PaidModal from "../Dashboard/PaidModal";
import { getDashboardData } from "../../Slice/DashboardSlice";
import UserProfile from "../../services/user_profile.service";
import { isUserPaid } from "../../utils/userPremiumUtils";
import GamePassModal from "../GamePassModal";
import CommonService from "../../services/common.service";
import GameTour from "../MyGames/GameTour";
import GameAboutModal from "../Dashboard/GameAboutModal";
import BrandingDetailModal from "../Dashboard/BrandingDetailModal";

const Footer = (props) => {
  const brandingDetails = JSON.parse(localStorage.getItem("branding"));
  const userPaidForClub = isUserPaid();
  const dispatch = useDispatch();
  const { get_round } = useSelector((state) => state.common);
  const { dashboardData, userPayment } = useSelector(
    (state) => state.dashboard
  );
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [userProfile, setUserProfile] = useState({});
  useEffect(() => {
    if (userDetails) {
      dispatch(getDashboardData());
      UserProfile.getUserProfileData(userDetails?.slug).then((res) => {
        if (res.status === 200) {
          setUserProfile(res.data);
        }
      });
    }
  }, [dispatch]);

  const [infoBoxClass, setInfoBoxClass] = useState();

  const openInfoBox = () => {
    setInfoBoxClass("open");
    document.body.classList.add("stopscroll");
  };

  const hanldeCloseInfoBox = () => {
    setInfoBoxClass("");
    document.body.classList.remove("stopscroll");
  };

  const clubDetails = JSON.parse(localStorage.getItem("club_details"));

  const pathName = window.location.pathname;
  var checkLogin = localStorage.getItem("token");

  const [showPointModal, setShowPointModal] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const handleShowPointModal = (e) => {
    e.preventDefault();
    globalFunction(setShowPointModal, setGradeList);
  };
  const [showRoundModal, setShowRoundModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);

  const [currentRoundDetails, setCurrentRoundDetails] = useState({});
  const [nextRoundDetails, setNextRoundDetails] = useState({});
  const [isCurrent, setIsCurrent] = useState(true);
  const handleShowRoundModal = (e) => {
    e.preventDefault();
    setShowRoundModal(true);
    CommonService.getCurrentRoundDetail().then((res) => {
      if (res.status === 200) {
        setCurrentRoundDetails(res.data);
        setNextRoundDetails(res.nextRoundDetails);
        setIsCurrent(res.isCurrent);
      }
    });
  };
  // const TextWithEllipsis = ({ text, limit }) => {
  //   const convertedText = ReactHtmlParser(text);
  //   if (convertedText.length <= limit) {
  //     return convertedText;
  //   } else {
  //     const truncatedText = convertedText.substring(0, limit) + "...";
  //     return truncatedText;
  //   }
  // };

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);
  var textToDisplay = "";
  if (clubDetails?.entry_message) {
    textToDisplay = showMore
      ? clubDetails?.entry_message
      : clubDetails?.entry_message.slice(0, 200) + "...";
  }
  const [showGamePass, setShowGamePass] = useState(false);
  const handleCloseGamePass = () => setShowGamePass(false);
  const handleGamePassModalShow = () => {
    setShowGamePass(true);
  };

  // Show Game Tour Modal
  const [gameTourShow, setGameTourShow] = useState(false);
  const [tourData, setTourData] = useState(false);
  const handleGameTourClose = () => setGameTourShow(false);
  const handleGameTourShow = (e) => {
    e.preventDefault();
    setGameTourShow(true);

    CommonService.getFeaturePageData(5).then((res) => {
      setTourData(res.data);
    });
  };
  // Show Game Tour Modal

  /* Show about description Modal */
  const [showGameAboutModal, setShowGameAboutModal] = useState(false);
  const [aboutDescription, setAboutDescription] = useState(false);
  const handleGameAboutModalClose = () => setShowGameAboutModal(false);
  const handleGameAboutModalShow = (text) => {
    setAboutDescription(text);
    setShowGameAboutModal(true);
  };
  /* Show about description Modal */
  /* Show Game Branding Modal */
  const [showBrandingModal, setShowBrandingModal] = useState(false);
  const handleBrandingModalClose = () => setShowBrandingModal(false);
  const handleBrandingModalShow = () => {
    setShowBrandingModal(true);
  };
  /* Show Game Branding Modal */

  return (
    <>
      {brandingDetails && (
        <div
          className="countdata"
          style={{
            backgroundColor: "#CA1744",
            width: "100%",
            margin: "0px",
            padding: "7px 0px 1px",
            borderRadius: "0px",
            maxWidth: "100%",
          }}
        >
          <div className="gameweek-filter">
            <h4
              style={{
                color: "#000000 !important",
                fontWeight: "500",
                margin: "5px 0 5px",
                backgroundColor: "#CA1744",
              }}
              class="customh4"
            >
              <span style={{ color: "#FFD43C" }}>Fantasy Partner:</span>{" "}
              <Link
                onClick={handleBrandingModalShow}
                style={{ color: "white" }}
              >
                {brandingDetails?.name}
              </Link>
            </h4>
          </div>
        </div>
      )}
      {pathName !== "/member-signup" &&
        pathName !== "/login" &&
        pathName !== "/start-game" &&
        pathName !== "/member-signup" && <GoogleAdsense />}
      <footer>
        <div className="container-fluid">
          <div className="topfooter">
            <div className="about-footer">
              <figure>
                <img src="/images/logo.png" alt="img" />
              </figure>
              <p>
                Get a custom fantasy bowls platform for your club or league.
              </p>
              <ul className="social_links">
                <li>
                  <a
                    title="facebook"
                    href="https://www.facebook.com/myclubtap/"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a title="twitter" href="https://twitter.com/myclubtap">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    title="linkedin"
                    href="https://www.linkedin.com/company/myclubtap/"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/myclubtap" title="instagram">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCxkEHoFm634u8L3-tHkMMgQ"
                    title="youtube"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="link">
              <h4>link</h4>
              <div className="collapse" id="footer1">
                <ul>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/features">Features</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  {/* <li>
                  <Link href="">Blogs</Link>
                </li>
                <li>
                  <Link href="">Partner With Us</Link>
                </li> */}
                </ul>
              </div>
            </div>
            <div className="about">
              <h4>About</h4>
              <div className="collapse" id="footer2">
                <ul>
                  {/* <li>
                  <Link href="">Press Release</Link>
                </li> */}
                  <li>
                    <Link to="/terms-of-use">Terms of use</Link>
                  </li>
                  <li>
                    <Link to="/privacy">Privacy</Link>
                  </li>
                  <li>
                    <Link to="/refund">Refund</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="get-started">
              <h4>Get Started</h4>
              <div className="collapse" id="footer3">
                <ul>
                  <li>
                    <Link to={"/how-it-works"}>How It Works</Link>
                  </li>
                  <li>
                    <Link to={"/how-to-play"}>How To Play</Link>
                  </li>
                  {/* <li>
                  <Link href="">Contact Us</Link>
                </li> */}
                  {/* <li>
                  <Link href="">Members</Link>
                </li> */}
                  {/* <li>
                  <Link href="">Community</Link>
                </li> */}
                </ul>
              </div>
            </div>
            <div className="more">
              <h4>More</h4>
              <div className="collapse" id="footer4">
                <ul>
                  <li>
                    <a href="https://testimonials.myclubtap.com/">
                      Share Feedback
                    </a>
                  </li>
                  <li>
                    <Link to={"/testimonials"}>Testimonials</Link>
                  </li>
                  <li>
                    <Link to="https://myclubtap.tawk.help/">Help Center</Link>
                  </li>
                  {/* <li>
                  <Link href="">Roadmap</Link>
                </li>
                <li>
                  <Link href="">MCT Games Map</Link>
                </li> */}
                </ul>
              </div>
            </div>
          </div>
          <p className="copyyright">&copy; MyClubtap 2024</p>
        </div>
      </footer>
      {checkLogin && pathName !== "/dashboard" && (
        <ul className="game-btn">
          <li>
            <a href="/dashboard">
              <img src="/images/dashboard.svg" alt="img" />
              Dashboard
            </a>
          </li>
          <li>
            <Link to="/my-team">
              <img src="/images/dashboard.svg" alt="img" />
              Team
            </Link>
          </li>
          <li>
            <Link id="gameprofile" onClick={openInfoBox}>
              <img src="/images/info-white-icon.png" alt="img" />
              Info
            </Link>
          </li>
          <li>
            <Link id="gamemenu" onClick={props.gameMenuClick}>
              <img src="/images/menu-icon.png" alt="img" />
              Menu
            </Link>
          </li>
        </ul>
      )}
      {pathName === "/my-games" && (
        <ul className="game-btn">
          <li>
            <Link to={`/member-profile/${userDetails?.slug}`}>My Profile</Link>
          </li>
          <li>
            <a href="/update-profile">Edit Profile</a>
          </li>
          <li>
            <Link onClick={handleGameTourShow}>Tour</Link>
          </li>
        </ul>
      )}
      {pathName === `/member-profile/${userDetails?.slug}` && (
        <ul className="game-btn">
          <li>
            <Link to={`/my-games`}>My Games</Link>
          </li>
          <li>
            <Link id="gameprofile" onClick={openInfoBox}>
              <img src="/images/info-white-icon.png" alt="img" />
              Info
            </Link>
          </li>
        </ul>
      )}

      {pathName === "/dashboard" && (
        <ul className="game-btn">
          <li>
            <a href="/my-team">
              <img src="/images/dashboard.svg" alt="img" />
              Team
            </a>
          </li>
          <li>
            <Link to="/rank">
              <img src="/images/rank-icon.png" alt="img" />
              Rank
            </Link>
          </li>
          <li>
            <Link id="gameprofile" onClick={openInfoBox}>
              <img src="/images/info-white-icon.png" alt="img" />
              Info
            </Link>
          </li>
          <li>
            <Link id="gamemenu" onClick={props.gameMenuClick}>
              <img src="/images/menu-icon.png" alt="img" />
              Menu
            </Link>
          </li>
        </ul>
      )}

      {/* <li>
          <a href="/rank">
            <img src="images/rank-icon.png" alt="img" />
            Rank
          </a>
        </li> */}

      <div className="gamemenu-section">
        <div className={props.gameMenuLink}>
          <figure className="menulogo">
            <img src="/images/logo.png" alt="img" />
          </figure>
          <div className="left-section">
            <figure>
              <img
                src={`${process.env.REACT_APP_UPLOAD_URL}game_logo/${clubDetails?.logo}`}
                alt="img"
              />
            </figure>
            <div className="gamebar_detail" style={{ color: "white" }}>
              {clubDetails?.game_name}
            </div>
          </div>
          <Link
            onClick={(e) => e.preventDefault()}
            className="close"
            id="closemenu"
          >
            <img src={`../images/close-icon.png`} alt="img" />
          </Link>
          <ul>
            <li>
              <Link to="/dashboard">My Dashboard</Link>
            </li>
            <li>
              <Link to="/my-team">My Team</Link>
            </li>
            <li>
              <Link to="/rounds">Round Team</Link>
            </li>
            {/* <li>
              <Link to="/history" >
                History
              </Link>
            </li> */}
            <li>
              <Link to="/rank">Rank</Link>
            </li>
            <li>
              <Link to="/players">Players</Link>
            </li>
            <li>
              <Link to="/player-charts">Player Charts</Link>
            </li>
            <li>
              <Link to="/timeline">Timeline</Link>
            </li>
            {clubDetails?.is_bracket_battle_activate === 1 && (
              <li>
                <Link to="/bracket-battle">Bracket Battle</Link>
              </li>
            )}
            {clubDetails?.is_bonus_card_activate === 1 && (
              <li>
                <Link to="/bonus-card">Bonus Card</Link>
              </li>
            )}
            {clubDetails?.is_totr_activate === 1 && (
              <li>
                <Link to="/team-of-the-round">TOTR</Link>
              </li>
            )}
            {clubDetails?.is_h2h_activate === 1 && (
              <li>
                <Link to="/head-2-head">Head-2-Head</Link>
              </li>
            )}
            <li>
              <Link to="/stats">Stats</Link>
            </li>
            <li>
              <Link to="/fixtures">Fixtures</Link>
            </li>
            <li>
              <Link to="/game-articles">Articles</Link>
            </li>
            <li>
              <Link to="/notifications">Notifications</Link>
            </li>
            <li>
              <Link to="/sponsors">Sponsors</Link>
            </li>
            {/* <li>
              <Link to="game-stats" >
                Game Stats
              </Link>
            </li> */}
            <li>
              <Link to="/game-members">Game Members</Link>
            </li>
            <li>
              <Link to="/trade-history">Trade History</Link>
            </li>
            <li>
              <Link to="/game-charts">Game Charts</Link>
            </li>

            {clubDetails?.is_team_activate === 1 && (
              <li>
                <Link to="/game-teams">Game Teams</Link>
              </li>
            )}

            <li>
              <Link to="/rules">Rules</Link>
            </li>
            <li>
              <Link to="/faqs">FAQs</Link>
            </li>
            <li>
              <Link to="/how-to-play">How To Play</Link>
            </li>
            <li>
              <Link onClick={(e) => handleShowPointModal(e)}>
                Points System
              </Link>
            </li>
            <li>
              <Link
                onClick={(e) => {
                  handleShowRoundModal(e);
                }}
              >
                Rounds/Trade Windows
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to={`https://myclubtap.tawk.help/category/for-members`}
                target="_blank"
              >
                Help Center
              </Link>
            </li>
            {/*
            <li>
              <Link to="/articles" >
                Articles
              </Link>
            </li>
            <li>
              <Link to="/team-of-the-week" >
                TOTR
              </Link>
            </li>
            <li>
              <Link to="/bonus-card" >
                Bonus Card
              </Link>
            </li> */}
          </ul>
        </div>
        <div className={props.gameMenuOverlay}></div>
      </div>

      {pathName !== `/member-profile/${userDetails?.slug}` && (
        <div className={`gameprofile ${infoBoxClass}`}>
          <Link className="gameprofileclose" onClick={hanldeCloseInfoBox}>
            <img src="/images/close-icon-blue.png" alt="img" />
          </Link>
          <div className="whitebox">
            <div className="gamevideo">
              <figure>
                <img src="/images/video-img.jpg" alt="img" />
              </figure>
              {/* <a href="!#" className="playbtn">
                      <img src="images/play-icon.png" alt="img" />
                    </a> */}
            </div>
            <figure className="user-profile">
              {clubDetails && clubDetails?.logo && (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}game_logo/${clubDetails?.logo}`}
                  width="50"
                  height="50"
                  alt="img"
                />
              )}
              {!clubDetails ||
                (!clubDetails?.logo && (
                  <img src="/images/brand-logo.png" alt="img" />
                ))}
            </figure>
            <div className="profile-content">
              <h4>{clubDetails?.game_name}</h4>
              <Link
                className="border-btn"
                onClick={(e) => handleShowPointModal(e)}
              >
                Points
              </Link>
              <Link
                className="border-btn"
                onClick={(e) => {
                  handleShowRoundModal(e);
                }}
              >
                Rounds
              </Link>

              <Link to={"/rules"} className="border-btn">
                Rules
              </Link>
              <Link to={"/faqs"} className="border-btn">
                FAQs
              </Link>

              <div className="admindetail">
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                  {clubDetails?.full_name}
                </span>
                <span>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  {clubDetails?.email}
                </span>
              </div>
              <ul className="social-link">
                <li>
                  <a href={clubDetails?.facebook}>
                    <img src="/images/fb-icon.svg" alt="img" />
                  </a>
                </li>
                <li>
                  <a href={clubDetails?.twitter}>
                    <img src="/images/twitter-icon.svg" alt="img" />
                  </a>
                </li>
                <li>
                  <a href={clubDetails?.instagram}>
                    <img src="/images/insta-icon.svg" alt="img" />
                  </a>
                </li>
                <li>
                  <a href={clubDetails?.website}>
                    <img src="/images/web-icon.svg" alt="img" />
                  </a>
                </li>
              </ul>
              {clubDetails?.entry_message && (
                <div className="p-2 fw-bold">
                  <Link
                    onClick={() =>
                      handleGameAboutModalShow(clubDetails?.entry_message)
                    }
                  >
                    Welcome Message
                  </Link>
                </div>
              )}

              <ul className="bottom-link">
                <li>
                  {dashboardData?.collect_game_pass === 0 && (
                    <Link>
                      Entry:&nbsp;<span>Free</span>
                    </Link>
                  )}
                  {dashboardData?.collect_game_pass === 1 && (
                    <>
                      {userPayment && userPayment?.is_paid == 1 && (
                        <Link>
                          Entry:&nbsp;<span>Paid</span>
                        </Link>
                      )}

                      {!userPayment && (
                        <Link
                          onClick={() => setShowPaymentModal(!showPaymentModal)}
                        >
                          Entry:&nbsp;
                          <span>${dashboardData?.game_pass_fee}</span>
                        </Link>
                      )}
                    </>
                  )}
                  {dashboardData?.collect_game_pass === 2 && (
                    <Link onClick={() => setShowPaidModal(!showPaidModal)}>
                      Entry:&nbsp;<span>${dashboardData?.game_pass_fee}</span>
                    </Link>
                  )}
                </li>
                <li className="active">
                  {userPaidForClub === 1 ? (
                    <Link
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      MCT PRO
                    </Link>
                  ) : (
                    <Link
                      onClick={(event) => {
                        event.preventDefault();
                        handleGamePassModalShow();
                      }}
                    >
                      Buy MCT PRO
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {pathName === `/member-profile/${userDetails?.slug}` && (
        <div className={`gameprofile ${infoBoxClass}`}>
          <Link className="gameprofileclose" onClick={hanldeCloseInfoBox}>
            <img src="/images/close-icon-blue.png" alt="img" />
          </Link>
          <div className="whitebox">
            <div className="gamevideo">
              <figure>
                <img src="/images/video-img.jpg" alt="img" />
              </figure>
            </div>
            <figure className="user-profile">
              {userDetails?.image && (
                <img
                  src={`${process.env.REACT_APP_USER_PROFILE}${userDetails?.image}`}
                  alt="img"
                  width="50"
                  height="50"
                />
              )}
              {!userDetails?.image && (
                <img src={`../images/user-img.png`} alt="img" />
              )}
            </figure>
            <div className="profile-content">
              <h4>{userDetails?.full_name}</h4>

              <div className="admindetail">
                <span>
                  <i className="fa fa-user" aria-hidden="true"></i>
                  {userDetails?.user_role_id == 2 ? "Member" : "Admin"}
                </span>
              </div>
              <ul className="social-link">
                {userProfile?.facebook && (
                  <li>
                    <a href={userProfile?.facebook}>
                      <img src="../images/fb-icon.svg" alt="img" />
                    </a>
                  </li>
                )}
                {userProfile?.twitter && (
                  <li>
                    <a href={userProfile?.twitter}>
                      <img src="../images/twitter-icon.svg" alt="img" />
                    </a>
                  </li>
                )}
                {userProfile?.instagram && (
                  <li>
                    <a href={userProfile?.instagram}>
                      <img src="../images/insta-icon.svg" alt="img" />
                    </a>
                  </li>
                )}
                {userProfile?.website && (
                  <li>
                    <a href={userProfile.website}>
                      <img src="../images/web-icon.svg" alt="img" />
                    </a>
                  </li>
                )}
              </ul>

              <p style={{ wordBreak: "break-all" }}>
                {" "}
                {ReactHtmlParser(userProfile?.introduction)}
              </p>
            </div>
          </div>
        </div>
      )}
      <PointModal
        visible={showPointModal}
        setVisible={setShowPointModal}
        gradeList={gradeList}
      />
      <PaidModal
        visible={showPaidModal}
        setVisible={setShowPaidModal}
        dashboardData={dashboardData}
        userPayment={userPayment}
      />
      <RoundModal
        round_data={get_round}
        visible={showRoundModal}
        setVisible={setShowRoundModal}
        currentRoundDetails={currentRoundDetails}
        isCurrent={isCurrent}
        nextRoundDetails={nextRoundDetails}
      />
      <PaymentModal
        visible={showPaymentModal}
        setVisible={setShowPaymentModal}
        amount={dashboardData?.game_pass_fee}
        type={1}
        noOfFixture={0}
      />
      <GamePassModal
        showGamePass={showGamePass}
        handleCloseGamePass={handleCloseGamePass}
      />
      <GameTour
        gameTourShow={gameTourShow}
        handleGameTourClose={handleGameTourClose}
        setGameTourShow={setGameTourShow}
        tourData={tourData}
      />
      <GameAboutModal
        showGameAboutModal={showGameAboutModal}
        handleGameAboutModalClose={handleGameAboutModalClose}
        handleGameAboutModalShow={handleGameAboutModalShow}
        aboutDescription={aboutDescription}
      />
      <BrandingDetailModal
        showBrandingModal={showBrandingModal}
        handleBrandingModalClose={handleBrandingModalClose}
        handleBrandingModalShow={handleBrandingModalShow}
      />
    </>
  );
};

export default Footer;
